@use 'mixins';

.PromoItem {
    display: flex;
    flex-direction: column;
    padding: var(--standardPadding);
    border-bottom: 1px solid rgb(var(--sectionBorder));
    line-height: 1.25em;

    .betting-content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    :global(.betting-disabled) & {
        display: none;
    }

    a {
        color: var(--textColor);
        margin: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    h4 {
        display: block;
        width: 100%;
        color: rgb(var(--textColor));
        margin: auto var(--standardPadding) auto auto;
    }

    .tag-wrapper {
        margin-right: var(--tinyPadding);
    }

    .logo-wrapper {
        display: inline-flex;
        align-items: center;

        img,
        span {
            display: block;
            width: auto;
            height: 1em;
            margin-right: 0;
        }
    }

    &.big {
        .logo-wrapper {
            align-items: center;
        }
    }

    .content {
        display: inline-flex;
        flex: 1;
        flex-direction: row;
    }

    .betting-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: var(--standardPadding);

        .logo-betting {
            svg {
                height: 20px;
                aspect-ratio: 96/20;
            }
        }
    }

    @include mixins.responsive(l, 'below') {
        .tag-wrapper {
            margin-right: var(--tinyPadding);
        }

        .logo-wrapper {
            height: 100%;
            text-align: right;
        }

        :global(.tag) {
            text-align: center;
        }
    }

    @include mixins.responsive(l, 'above') {
        .logo-wrapper a {
            margin-right: var(--smallPadding);
        }
    }
}
