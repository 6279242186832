.ShowMoreLink {
    padding: 0 !important;
    font-weight: 700;
    font-family: var(--textFont);
    text-decoration: none;

    [data-theme='vp'] &,
    [data-theme='vpbe'] &,
    [data-theme='vn'] & {
        color: rgb(var(--linkColor));
    }

    [data-theme='gp'] &,
    [data-theme='vi'] & {
        color: rgb(var(--mainColor));
    }

    &:hover {
        color: rgb(var(--mainColor)) !important;

        [data-theme='vi'] & {
            color: rgb(var(--mainColorLighter)) !important;
        }
    }

    [data-theme='gp'] & {
        text-transform: uppercase;
        letter-spacing: 0.4px;
    }
}
