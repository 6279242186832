.IconTabs {
    display: flex;

    li {
        padding: var(--tinyPadding);
        margin-right: var(--smallPadding);

        svg,
        path,
        g {
            color: rgb(var(--lighterTextColor));
        }

        &:hover {
            cursor: pointer;

            svg,
            path,
            g {
                pointer-events: none;
                color: rgb(var(--mainColor));
                fill: rgb(var(--mainColor));

                [data-theme='vp'] &,
                [data-theme='vn'] & {
                    color: rgb(var(--thirdColor));
                    fill: rgb(var(--thirdColor));
                }

                [data-theme='gp'] &,
                [data-theme='br'] & {
                    color: rgb(var(--mainHoverColor));
                    fill: rgb(var(--mainHoverColor));
                }
            }
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.selected {
            border-bottom: 3px solid var(--mainColor);

            svg,
            path,
            g {
                pointer-events: none;
                color: rgb(var(--mainColor));
                fill: rgb(var(--mainColor));

                [data-theme='vp'] &,
                [data-theme='vn'] & {
                    color: rgb(var(--thirdColor));
                    fill: rgb(var(--thirdColor));
                }
            }
        }
    }
}
