@use 'mixins';

%-heading-underlined {
    border-bottom: var(--standardBorderWidth) solid rgb(var(--thirdColor));

    [data-theme='vi'] & {
        border-bottom-color: rgb(var(--mainColor));
    }

    [data-theme='gp'] & {
        font-size: 1.125em;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        color: rgb(var(--fourthColor));
        border-bottom: none;
    }
}

.NewsList {
    display: flex;
    flex-direction: column;
    font-family: var(--textFont);

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: inherit;
        }
    }

    ul {
        list-style-type: none;
    }

    .divider {
        display: flex;
    }

    .injection {
        border-bottom: 1px solid rgb(var(--sectionBorder));

        :global(.poll) {
            padding-bottom: var(--standardPadding);
        }
    }

    .injection-margin-top {
        margin-top: var(--standardPadding);
    }

    .injection-margin-y {
        margin: var(--standardPadding) 0;
    }

    .injection-margin-bottom {
        margin-bottom: var(--standardPadding);
    }

    &.blocks-display-type {
        .divider {
            [data-theme='vi'] & {
                margin-bottom: var(--standardPadding);
                padding: var(--standardPadding) var(--standardPadding) 0 var(--standardPadding);
            }

            h2 {
                @extend %-heading-underlined;
            }
        }

        > ul {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: var(--standardPadding) var(--tinyPadding);

            li.divider:first-of-type {
                flex-basis: 100%;
            }

            [data-theme='vi'] & {
                > li {
                    display: block;
                    width: calc(33.3% - 0.75em);
                    margin-right: var(--standardPadding);
                    margin-bottom: var(--standardPadding);
                }
            }
        }
    }

    &.carrousel-display-type {
        .divider {
            [data-theme='vi'] & {
                padding: var(--standardPadding) var(--standardPadding) 0 var(--standardPadding);
            }

            h2 {
                @extend %-heading-underlined;
            }
        }

        ul {
            @include mixins.horizontalScrollSnap;

            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            margin: var(--standardPadding) var(--tinyPadding);
        }

        .carrousel-wrapper {
            display: block;
            position: relative;

            // Overlay gradient over the carrousel
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin-right: calc(var(--smallPadding) * -1);
                background: linear-gradient(
                    90deg,
                    rgb(var(--background)) 0%,
                    transparent 8%,
                    transparent 92%,
                    rgb(var(--background)) 100%
                );
                content: '';
                pointer-events: none;
            }

            &:global(.is-highlighted)::after {
                display: none;
            }

            &:has(.on-start-position) {
                &::after {
                    background: linear-gradient(90deg, transparent 92%, rgb(var(--background)) 100%);
                }

                .arrow-prev {
                    display: none;
                }
            }

            &:has(.on-end-position) {
                &::after {
                    background: linear-gradient(90deg, rgb(var(--background)) 0%, transparent 8%);
                }

                .arrow-next {
                    display: none;
                }
            }

            // if carrousel has exactly 3 elements
            &:has(.on-start-position.on-end-position) {
                &::after {
                    background: unset;
                }
            }
        }

        .arrow-next {
            right: 0;
            margin-right: calc(var(--smallPadding) * -1);
        }

        .arrow-prev {
            margin-left: calc(var(--smallPadding) * -1);
        }

        .arrow-next,
        .arrow-prev {
            position: absolute;
            top: calc(var(--largePadding) + 4.5vw); // image height is 9vw
            background-color: rgb(var(--background));
            z-index: 1;
            cursor: pointer;
            transform: translateY(-50%); // the half of it's own height
            letter-spacing: 1px;
            box-shadow: 1px 1px 1px 1px rgba(var(--invertedBackground), 0.2);

            [data-theme='br'] & {
                top: calc(var(--largePadding) + 3vw);
                border-radius: 50%;
            }

            [data-theme='gp'] & {
                background-color: rgb(var(--fourthColor));
                border-radius: 50%;

                :global(.Icon) {
                    color: rgb(var(--invertedTextColor));
                }
            }

            :global(.Icon) {
                margin: var(--smallPadding);
                font-size: 1.5em;
                color: rgb(var(--thirdColor));
            }
        }

        &:global(.is-highlighted) {
            position: relative;
            padding: var(--standardPadding) 0 var(--standardPadding) 0;

            [data-theme='br'] & {
                &::before {
                    display: block;
                    position: relative;
                    top: 0;
                    right: 0;
                    width: var(--right-shape-width);
                    height: var(--right-shape-height);
                    margin: auto 0 auto auto;
                    background: rgb(var(--mainColor));
                    z-index: 0;
                    content: '';
                    transform: translateY(60%);
                    clip-path: var(--right-shape-clip);
                }

                &::after {
                    display: block;
                    position: absolute;
                    top: calc(var(--smallPadding) * -1);
                    right: 0;
                    width: 100%;
                    height: var(--standardPadding);
                    background: rgb(var(--invertedTextColor));
                    z-index: 0;
                    content: '';
                }

                :global(.play-button) {
                    display: none;
                }

                .arrow-next,
                .arrow-prev {
                    top: 6.25em;
                    padding: 0;
                }
            }

            .divider {
                padding-right: var(--largePadding);
                padding-left: var(--largePadding);
                border-bottom: 0;

                h2 {
                    border-bottom: 0;
                }
            }

            h2 {
                display: flex;
                width: 100%;
                margin-bottom: 0;
                padding-bottom: 1rem;
                padding-left: 1rem;
                background: rgb(var(--invertedBackground));
                z-index: 1;
                text-decoration: underline;
                color: rgb(var(--invertedTextColor));
                text-decoration-color: rgb(var(--mainColor));
                text-underline-offset: 0.3em;
                text-decoration-thickness: 3px;

                [data-theme='br'] & {
                    margin-top: 0;
                    font-size: 1.75em;
                    line-height: 2em;
                    letter-spacing: initial;
                    text-transform: capitalize;
                    text-underline-offset: 0.625em;

                    &::after {
                        display: block;
                        flex: 1;
                        width: 100%;
                        height: 100%;
                        margin: auto 0 0 0.2em;
                        content: '';
                        transform: translateY(2px);
                        border-bottom: 1px solid rgba(var(--invertedTextColor), 0.3);
                    }
                }
            }

            > li {
                background: rgb(var(--invertedBackground));

                [data-theme='br'] & {
                    flex-direction: row;
                    position: relative;
                    padding-top: 0;

                    &::before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: var(--left-shape-width);
                        height: var(--left-shape-height);
                        background: rgb(var(--invertedBackground));
                        z-index: 1;
                        content: '';
                        transform: translateY(-99%);
                        clip-path: var(--left-shape-clip);
                    }
                }
            }

            > .carrousel-wrapper {
                background: rgb(var(--invertedBackground));
            }

            .arrow-next {
                right: var(--standardPadding);
                margin-right: 0;

                [data-theme='br'] & {
                    margin-right: var(--tinyPadding);
                }
            }

            .arrow-prev {
                left: var(--standardPadding);
                margin-left: 0;

                [data-theme='br'] & {
                    margin-left: var(--largePadding);
                }
            }
        }
    }

    :global(.poll) {
        p {
            padding: 0 var(--standardPadding) var(--standardPadding) var(--standardPadding);
            font-family: var(--textFont);
            font-size: 1em;
            line-height: 1.625em;
            color: var(--textColor);
        }

        a {
            color: rgb(var(--linkColor));
        }
    }

    @include mixins.responsive(l, below) {
        &:global(.is-highlighted) {
            > li {
                padding: var(--smallPadding) var(--standardPadding) 0 var(--standardPadding);
            }

            ul {
                margin: var(--smallPadding) var(--smallPadding) 0 var(--standardPadding);

                [data-theme='br'] & {
                    margin: 0 0 0 calc(var(--largePadding) - var(--tinyPadding));
                }
            }
        }

        .arrow-next,
        .arrow-prev {
            top: calc(var(--largePadding) + 6.5vw); // image height is 13vw
        }
    }

    @include mixins.responsive(l, above) {
        &:global(.is-highlighted) {
            > li {
                padding: var(--standardPadding) var(--largePadding) 0 var(--largePadding);
            }

            ul {
                margin: 0 0 0 calc(var(--largePadding) - var(--tinyPadding));
            }
        }
    }

    @include mixins.responsive(m, below) {
        .arrow-next,
        .arrow-prev {
            display: none;
        }

        &.carrousel-display-type {
            &:global(.is-highlighted) {
                [data-theme='br'] & {
                    h2 {
                        padding-bottom: 0;
                    }

                    .arrow-next,
                    .arrow-prev {
                        display: block;
                        top: calc(var(--largePadding) + 20vw);
                    }

                    .arrow-prev {
                        margin-left: var(--smallPadding);
                    }

                    .arrow-next {
                        margin-right: var(--tinyPadding);
                    }

                    .divider {
                        padding-right: var(--standardPadding);
                        padding-left: var(--standardPadding);
                    }

                    ul {
                        margin-left: calc(var(--standardPadding) - var(--tinyPadding));
                    }
                }
            }
        }

        .divider {
            h3 {
                font-size: 0.875em;
            }
        }
    }

    @include mixins.responsive(xxxl, above) {
        .arrow-next,
        .arrow-prev {
            top: calc(var(--largePadding) + 80px); // image height is 160px
            background-color: rgb(var(--thirdColor));
            color: rgb(var(--invertedTextColor));

            [data-theme='gp'] & {
                background-color: transparent;
                color: rgb(var(--mainColor));
            }
        }
    }
}
